import { AxiosRequestConfig } from "axios";
import { axiosInstance } from "..";

const optionDefaults: AxiosRequestConfig = {
  method: "GET",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  data: {},
  withCredentials: true,
  withXSRFToken: true,
};

export const Api = async (
  url: String,
  options: AxiosRequestConfig | null = null,
) => {
  // Remove prefix slash from url
  url = url.replace(/^\//, "");

  // Ensure baseUri has trailing slash
  const baseUri = `${(
    process.env.REACT_APP_API_ENDPOINT || "http://localhost/api"
  ).replace(/\/?$/, "")}/`;

  if (!options) options = optionDefaults;

  if (
    options.method === "POST" ||
    options.method === "PUT" ||
    options.method === "PATCH" ||
    options.method === "DELETE"
  ) {
    // Request requires CSRF token
    const csrfOptions = optionDefaults as AxiosRequestConfig;
    csrfOptions.data = undefined;
    return await axiosInstance(baseUri + "csrf", csrfOptions).then(async () => {
      if (!options.headers) options.headers = optionDefaults.headers;

      if (options.data && options.data.file && options.headers) {
        options.headers["Content-Type"] = "multipart/form-data";
        options.data["_method"] = options.method;
        options.method = "POST";
      }

      return axiosInstance(baseUri + url, options)
        .then(async (response) => {
          if (response.status >= 200 && response.status < 400) {
            return response.data;
          }
        })
        .catch((error) => {
          const errors = error.response.data.errors
            ? Object.values(error.response.data.errors)
                .join(", ")
                .replace(/\./g, "") + "."
            : error.response.data.message;
          throw new Error(errors);
        });
    });
  } else {
    options.data = undefined;
    return axiosInstance(baseUri + url, options)
      .then(async (response) => {
        if (response.status >= 200 && response.status < 400) {
          return response.data;
        }
      })
      .catch((error) => {
        throw new Error(
          error.response.data.message || error.response.data.error,
        );
      });
  }
};
