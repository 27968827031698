import { Link } from "react-router-dom";
import CartIcon from "../icons/cart";
import ImageIcon from "../icons/image";
import VideoIcon from "../icons/video";
import { useCart } from "../services/CartContext";
import { Content } from "../types/Content";

interface Props {
  content: Content;
}

export default function Card({ content }: Props) {
  const { addToCart } = useCart();

  if (!content) return null;

  return (
    <article>
      <Link to={content.url} className="relative mb-md block">
        <span className="absolute right-sm top-sm block rounded-full bg-slate-500 bg-opacity-90 p-sm backdrop-blur-md">
          <ImageIcon />
        </span>
        <img
          src={content.thumbnails[0] || "/images/placeholder.png"}
          alt={content.description.title}
          width={960}
          height={640}
          className="rounded-md"
        />
      </Link>

      <Link to={content.url} className="mb-sm block">
        {content.description.title}
      </Link>

      <div className="flex justify-between gap-sm">
        <Link to={content.creator.url} className="flex min-w-0 gap-sm">
          <img
            src={content.creator.avatar}
            alt={content.creator.name}
            width={24}
            height={24}
            className="rounded-full"
          />
          <span className="truncate text-slate-100">
            {content.creator.name}
          </span>
        </Link>
        <div className="flex gap-sm">
          <span className="text-slate-100">
            {!content.owned
              ? content.price === 0
                ? "Free"
                : content.price_inc_tax_as_currency
              : "Owned"}
          </span>
          {!content.owned && (
            <button onClick={() => addToCart(content)}>
              <CartIcon />
            </button>
          )}
        </div>
      </div>
    </article>
  );
}
