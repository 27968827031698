const Image = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <circle
      cx={12}
      cy={13}
      r={3}
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
    <path
      stroke="currentcolor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M4 9V16C4 18.2091 5.79086 20 8 20H16C18.2091 20 20 18.2091 20 16V9C20 7.89543 19.1046 7 18 7H16L14 4H10L8 7H6C4.89543 7 4 7.89543 4 9Z"
    />
  </svg>
);
export default Image;
