import React, { useEffect, useState } from "react";

import useMarzipano, { ViewerOpts, SceneSpec } from "../services/useMarzipano";
import Marzipano from "marzipano";
import { HotspotContainer } from "../types/Marzipano";
import { HotspotIcon, SystemHotspot } from "../types/SystemHotspot";
import Button from "./button";
import { Api } from "../services/Api";
import { Content } from "../types/Content";
import { useNavigate } from "react-router-dom";
import EditHotspot from "./edithotspot";
import { useSanctum } from "react-sanctum";
import { VideoAsset } from "../types/VideoAsset";
export interface MarzipanoViewProps {
  content: Content;
  className?: string;
  style?: any;
  scene: Map<string, SceneSpec>;
  viewerOpts?: ViewerOpts;
  initialHotspots: SystemHotspot[];
}

export default function MarzipanoView({
  content,
  className,
  style,
  scene,
  viewerOpts,
  initialHotspots,
}: MarzipanoViewProps): React.ReactElement {
  const { viewerCanvas, scenes, viewer } = useMarzipano({
    scenes: scene,
    viewerOpts,
  });
  const [hotspotContainer, setHotspotContainer] =
    useState<HotspotContainer | null>(null);
  const [systemSpots, setSystemSpots] = useState<SystemHotspot[]>([]);
  const [active, setActive] = useState<string>("");
  const navigate = useNavigate();
  const { user, authenticated } = useSanctum();
  const Hammer = Marzipano.dependencies["hammerjs"];
  const [hammerMouse, setHammerMouse] = useState<any>(null);

  const [videoElement, setVideoElement] = useState();
  const [videoState, setVideoState] = useState("paused");

  const [hsError, setHsError] = useState<string>("");
  const [hsTitle, setHsTitle] = useState<string>("");
  const [hsText, setHsText] = useState<string>("");
  const [hsLink, setHsLink] = useState<number | undefined>(undefined);
  const [hsType, setHsType] = useState<"Link" | "Text">("Text");
  const [hsIcon, setHsIcon] = useState<HotspotIcon | undefined>(undefined);
  const [linkableContent, setLinkableContent] = useState<Content[]>([]);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [addMode, setAddMode] = useState<boolean>(false);

  const syncSystemToViewer = () => {
    Api("/content/" + content.id + "/hotspots").then((res) => {
      setSystemSpots(res);
    });
  };

  useEffect(() => {
    if (content) {
      Api(`/content?paginate=false`).then((res) => {
        setLinkableContent(
          res.filter((c: Content) => {
            return c.id != content.id;
          }),
        );
      });
    }
  }, [content, authenticated]);

  const waitForReadyState = (
    element: HTMLVideoElement,
    readyState: number,
    interval: number | undefined,
    done: { (): void; (): void; (arg0: null, arg1: boolean): void },
  ) => {
    var timer = setInterval(function () {
      if (element.readyState >= readyState) {
        clearInterval(timer);
        done(null, true);
      }
    }, interval);
  };

  const getIcon = (name: HotspotIcon | undefined) => {
    switch (name) {
      case "Image":
        return `<img src="/svg/Image.svg" width="48" height="48" />`;
      case "Video":
        return `<img src="/svg/Video.svg" width="48" height="48" />`;
      case "Audio":
        return `<img src="/svg/Audio.svg" width="48" height="48" />`;
      case "Question":
        return `<img src="/svg/Question.svg" width="48" height="48" />`;
      default:
        return `<img src="/svg/Hotspot.svg" width="48" height="48" />`;
    }
  };

  useEffect(() => {
    if (scenes.size > 0) {
      const tmp = scenes.get("1");
      if (tmp) {
        var asset = tmp._layers[0]._source._asset;
        if (asset instanceof VideoAsset) {
          var video = document.createElement("video");
          video.src = content.file_url;
          video.crossOrigin = "anonymous";
          video.autoplay = true;
          video.loop = true;
          video.playsInline = true;
          video.webkitPlaysInline = true;
          video.addEventListener("playing", function () {
            setVideoState("playing");
            waitForReadyState(video, video.HAVE_METADATA, 100, function () {
              waitForReadyState(
                video,
                video.HAVE_ENOUGH_DATA,
                100,
                function () {
                  asset.setVideo(video);
                },
              );
            });
          });
          setVideoElement(video);
        } else {
          const container = tmp.hotspotContainer();
          setHotspotContainer(container);
        }
      }
    }
  }, [viewerCanvas, scenes]);

  useEffect(() => {
    if (videoState == "playing" && !hotspotContainer) {
      const tmp = scenes.get("1");
      if (tmp) {
        const container = tmp.hotspotContainer();
        setHotspotContainer(container);
      }
    }
  }, [videoState]);

  useEffect(() => {
    if (hotspotContainer && !hotspotContainer._hotspots.length) {
      setSystemSpots(initialHotspots);
      initialHotspots.forEach(function (hotspot, index) {
        const id = hotspot.id;
        const element = document.createElement("div");
        element.dataset.index = id.toString();
        element.className =
          id.toString() === active ? "hotspot hotspot-active" : "hotspot";
        element.innerHTML = getIcon(hotspot.icon);
        element.onclick = (e) => {
          setActive(id.toString());
        };
        hotspotContainer.createHotspot(
          element,
          {
            yaw: parseFloat(hotspot.yaw.toString()),
            pitch: parseFloat(hotspot.pitch.toString()),
          },
          { perspective: { radius: 1000 } },
        );
      });
    }
  }, [hotspotContainer, initialHotspots]);

  useEffect(() => {
    // Update icon for all spots
    if (hotspotContainer && hotspotContainer._hotspots) {
      const hotspotsElements = document.getElementsByClassName("hotspot");
      for (let i = 0; i < hotspotsElements.length; i++) {
        const hotspot = hotspotContainer._hotspots[i];
        const hotspotElement = hotspotsElements[i];
        hotspotElement.innerHTML = getIcon(systemSpots[i].icon);
      }
    }
  }, [systemSpots]);

  useEffect(() => {
    if (hotspotContainer && hotspotContainer._hotspots) {
      hotspotContainer._hotspots.forEach(function (hotspot) {
        hotspot.domElement().className = "hotspot";
        if (hotspot.domElement().dataset.index == active) {
          hotspot.domElement().className = "hotspot hotspot-active";
          const systemSpot = systemSpots.find(
            (hotspot) => hotspot.id === parseInt(active),
          );
          if (systemSpot) {
            setHsType(systemSpot.type);
            setHsTitle(systemSpot.title ?? "");
            setHsText(systemSpot.text ?? "");
            setHsLink(systemSpot.linked_content_id);
            setHsIcon(systemSpot.icon);
          }
        }
      });
    }
  }, [active]);

  useEffect(() => {
    if (viewer) {
      setHammerMouse(viewer._hammerManagerMouse);
    }
  }, [viewer]);

  useEffect(() => {
    if (hammerMouse && viewer) {
      hammerMouse.manager().add(new Hammer.Tap({}));
      hammerMouse.on("tap", (e: any) => {
        const coords = viewer.view().screenToCoordinates(e.center);
        // Add hotspot -> then set active to new hotspot -> then display modal for hotspot
        if (
          hotspotContainer &&
          window.addMode !== undefined &&
          window.addMode === true
        ) {
          setLoading(true);
          Api("/content/" + content.id + "/hotspots", {
            method: "POST",
            data: {
              yaw: coords.yaw,
              pitch: coords.pitch,
              radius: 1000,
            },
          })
            .then((res) => {
              const id = res.id;
              const icon = res.icon;
              const element = document.createElement("div");
              element.dataset.index = id;
              element.className = "hotspot hotspot-active";
              element.innerHTML = getIcon(icon);
              element.onclick = (e) => {
                setActive(id.toString());
              };
              hotspotContainer.createHotspot(
                element,
                { yaw: Number(res.yaw), pitch: Number(res.pitch) },
                { perspective: { radius: Number(res.radius) } },
              );
              setHsType("Text");
              setHsTitle("");
              setHsText("");
              setHsLink(undefined);
              setHsIcon(undefined);
              setActive(id);
            })
            .then((_) => {
              setLoading(false);
              syncSystemToViewer();
              setHsError("");
              setShowEdit(true);
              setAddMode(false);
              window.addMode = false;
            });
        }
      });
    }
  }, [viewer, hotspotContainer]);

  const addClicked = () => {
    setAddMode(!addMode);
    window.addMode = !addMode;
  };

  const editClicked = () => {
    setShowEdit(true);
  };

  const updateHotspot = (
    type: "Link" | "Text" | "External Link" | "Video" | "Audio" | "Image",
    title?: string,
    text?: string,
    link?: number,
    icon?: HotspotIcon,
    file?: File,
  ) => {
    setLoading(true);
    setHsError("");
    if (file) {
      if (file.size > 256000000) {
        setHsError("Sorry, the file size must be less than 256MB.");
        return;
      }
    }
    Api("/content/" + content.id + "/hotspots/" + active, {
      method: "POST",
      data: {
        type: type,
        title: title ?? null,
        text: text ?? null,
        linked_content_id: link ?? null,
        icon: icon ?? null,
        file: file ?? null,
      },
    })
      .then((_) => {
        syncSystemToViewer();
        setActive("");
        setShowEdit(false);
      })
      .catch((error) => {
        setHsError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteClicked = () => {
    if (hotspotContainer && hotspotContainer._hotspots) {
      hotspotContainer._hotspots.forEach(function (hotspot) {
        if (hotspot.domElement().dataset.index === active) {
          setLoading(true);
          Api("/content/" + content.id + "/hotspots/" + active, {
            method: "DELETE",
          })
            .then((_) => {
              hotspotContainer.destroyHotspot(hotspot);
              setActive("");
            })
            .then((_) => {
              syncSystemToViewer();
            })
            .finally(() => {
              setLoading(false);
            });
        }
      });
    }
  };

  const videoHandler = () => {
    if (videoElement && videoState == "playing") {
      videoElement.pause();
      setVideoState("paused");
    } else if (videoElement && videoState == "paused") {
      videoElement.play();
      setVideoState("playing");
    }
  };
  return (
    <>
      {showEdit && (
        <EditHotspot
          loading={loading}
          error={hsError}
          type={hsType}
          title={hsTitle}
          text={hsText}
          link={hsLink}
          linkableContent={linkableContent}
          icon={hsIcon}
          onCancel={() => setShowEdit(false)}
          onClose={updateHotspot}
        />
      )}
      <div style={{ height: "5vh" }} className="truncate p-sm text-md">
        {content.title}
      </div>
      <div
        className={className}
        style={{
          position: "relative",
          width: "100vw",
          height: "100vh",
          ...style,
        }}
        ref={viewerCanvas}
      />
      <div
        style={{ height: "7vh" }}
        className="flex flex-row justify-between space-x-md p-sm"
      >
        <div className="flex space-x-sm">
          <Button primary onClick={addClicked}>
            {addMode ? "Click to add" : "Add"}
          </Button>
          <Button
            primary
            onClick={editClicked}
            disabled={
              active == "" || active == null || active == undefined || addMode
            }
          >
            Edit
          </Button>
          <Button
            primary
            onClick={deleteClicked}
            disabled={
              active == "" || active == null || active == undefined || addMode
            }
          >
            Delete
          </Button>
        </div>
        {videoElement && (
          <div>
            <Button
              primary
              onClick={videoHandler}
              disabled={videoState == "" || addMode}
            >
              {videoState == ""
                ? "Loading..."
                : videoState == "playing"
                  ? "Pause"
                  : "Play"}
            </Button>
          </div>
        )}
        <Button onClick={() => navigate("/library")}>Exit Editor</Button>
      </div>
    </>
  );
}
